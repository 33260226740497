import React from "react";

function Politica() {
	return (
		<div
			style={{
				fontSize: "1.5rem",
				width: "50vw",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				margin: "0 auto",
				marginTop: "10vh",
			}}
		>
			<p
				style={{
					marginBottom: "10px",
				}}
			>
				This privacy policy governs your use of the software application
				for Android mobile devices that was created by peral’s team.
			</p>
			<p
				style={{
					marginBottom: "10px",
				}}
			>
				What information we collect? Application does not collect
				personal information like name, surname, age, date of birth,etc.
				We use Google supplied APP performance tracking information to
				improve Application.
			</p>
			<p
				style={{
					marginBottom: "10px",
				}}
			>
				This application only makes use of the sound your Android mobile
				phone to create the alarm. Google Analytics This Application
				uses Google Analytics to help us analyse how you use app. It
				help us to improve this product. Thanks Google Analytics we know
				how long you use app, what kind of device you’re using, where
				are you from and a little bit more.
			</p>
			<p
				style={{
					marginBottom: "10px",
				}}
			>
				All this is used to improve the app. Read more about Google
				Analytics Advertisement Google Admob privacy policy is here:
				https://support.google.com/admob/answer/6128543?hl=en. Third
				party vendors, including Google, use cookies to serve ads based
				on a user’s prior visits to your website.
			</p>
			<p
				style={{
					marginBottom: "10px",
				}}
			>
				Google’s use of the DoubleClick cookie enables it and its
				partners to serve ads to your users based on their visit to your
				sites and/or other sites on the Internet. Users may opt out of
				the use of the DoubleClick cookie for interest-based advertising
				by visiting Ads Settings.
			</p>
			<p
				style={{
					marginBottom: "10px",
				}}
			>
				(Alternatively, you can direct users to opt out of a third-party
				vendor’s use of cookies for interest based advertising by
				visiting aboutads.info.)
			</p>
			<p
				style={{
					marginBottom: "10px",
				}}
			>
				Contact To contact us please use email: info@peralstudio.com
			</p>
		</div>
	);
}

export default Politica;
